import {
  locationNameRegex,
  nameRegex,
} from '../../AddLocation/components/validate';

const validate = values => {
  const errors = {};

  if (!values.LOCATION_NAME) {
    errors.LOCATION_NAME = 'Required field';
  }
  if (locationNameRegex.test(values.LOCATION_NAME)) {
    errors.LOCATION_NAME = 'Field contains forbidden characters';
  }
  if (!values.COMPANY_NAME) {
    errors.COMPANY_NAME = 'Required field';
  }
  if (nameRegex.test(values.COMPANY_NAME)) {
    errors.COMPANY_NAME = 'Field contains forbidden characters';
  }
  if (!values.LOCATION_POINT_SHORT_INFO) {
    errors.LOCATION_POINT_SHORT_INFO = 'Required field';
  }
  if (!values.POINT_COUNT && !values.COPY_AS_VARIANT) {
    errors.POINT_COUNT = 'Upload a model to obtain the number of points';
  }
  if (
    values.LOCATION_POINT_SHORT_INFO &&
    values.LOCATION_POINT_SHORT_INFO.length
  ) {
    values.LOCATION_POINT_SHORT_INFO.forEach((point, i) => {
      if (!point) {
        errors[i] = 'Required field';
      }
    });
  }
  if (nameRegex.test(values.LIST_ID)) {
    errors.LIST_ID = 'Field contains forbidden characters';
  }
  if (nameRegex.test(values.LOCATION_VARIANT_NAME)) {
    errors.LOCATION_VARIANT_NAME = 'Field contains forbidden characters';
  }
  if (nameRegex.test(values.LOCATION_LIST_NAME)) {
    errors.LOCATION_LIST_NAME = 'Field contains forbidden characters';
  }
  if (
    values.START_POINT &&
    values.LOCATION_POINT_SHORT_INFO[values.START_POINT - 1] === undefined &&
    values.MODEL_UPLOADED &&
    !values.PARENT_VARIANT
  ) {
    errors.START_POINT = 'Wrong point';
  }
  if (values.INSTANT_PANO && values.START_POINT === '') {
    errors.START_POINT = 'Specify starting point';
  }
  if (!isNaN(values.TOOLBAR_VALUE) && values.TOOLBAR_VALUE < 0) {
    errors.TOOLBAR_VALUE = 'Hotspot ID cannot be less than 0';
  }

  if (values.DOCUMENT_TITLE && values.DOCUMENT_TITLE.length > 35) {
    errors.DOCUMENT_TITLE = 'Length should not exceed 35 characters';
  }
  if (values.DOCUMENT_TITLE && !/^[A-Za-z0-9: ]+$/.test(values.DOCUMENT_TITLE)) {
    errors.DOCUMENT_TITLE = 'Can contain only Latin letters, numbers and :';
  }

  if (values.DOCUMENT_TITLE && values.DOCUMENT_TITLE.length > 15) {
    errors.DOCUMENT_TITLE = 'Length should not exceed 15 characters';
  }
  if (values.DOCUMENT_TITLE && !/^[a-zA-Z ]+$/.test(values.DOCUMENT_TITLE)) {
    errors.DOCUMENT_TITLE = 'Can contain only Latin letters';
  }

  return errors;
};

export default validate;
