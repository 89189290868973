import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';

const CustomFormControl = ({
  input,
  label,
  min,
  placeholder,
  type,
  meta: { touched, error },
  as,
  readOnly,
  span,
  hide,
  onChangeValidate,
  children,
}) => (
  <Form.Group as={Row} className={hide ? 'd-none' : ''}>
    {label && (
      <Form.Label column sm={span ? 12 - span : 3}>
        {label}
      </Form.Label>
    )}
    <Col sm={span || 9}>
      <Form.Control
        min={min === undefined ? 1 : min}
        {...input}
        type={type}
        placeholder={placeholder || label}
        aria-label={placeholder || label}
        as={as}
        readOnly={readOnly}
        value={
          input.value === 'null' || input.value === 'NULL' ? '' : input.value
        } // Use value instead of defaultValue
        onChange={input.onChange} // Pass the onChange handler
      >
        {children}
      </Form.Control>
      {(onChangeValidate ? true : touched) && error && (
        <span className="text-danger">{error}</span>
      )}
    </Col>
  </Form.Group>
);

export default CustomFormControl;
