import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  loadLocationsOfCompany,
  resetCompanyLocations,
  showContextMenu,
} from '../../../../redux/actions';
import { connect } from 'react-redux';
import LocationItem from '../LocationItem';
import Folder from '../Folder';
import { Col, Row } from 'react-bootstrap';
import Multilocation from '../Multilocation';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { GridProvider } from '../../../../shared/utils/DragAndDrop/GridProvider';
import PlusItemCard from '../PlusItemCard';
import EmptyPlus from '../EmptyPlus';
import {
  useCreateMultilocation,
  useDeleteMultilocation,
  useRenameMultilocation,
} from './hooks';
import { MOVE_OBJECT_TYPE } from '../ContextMenu/ContextMenu';
import ContextMenu from '../ContextMenu';

function Company({
  loadLocationsOfCompany,
  resetCompanyLocations,
  companyData,
  settings,
  searchName,
  companies,
  showContextMenu,
  auth,
}) {
  const { company } = useParams();
  const companyDecoded = decodeURIComponent(company);
  const { path } = useRouteMatch();
  const history = useHistory();
  const [locationKeys, setLocationKeys] = useState([]);

  const deleteMultilocation = useDeleteMultilocation(companyDecoded);
  const createMultilocation = useCreateMultilocation(
    companies,
    companyData,
    companyDecoded,
    auth
  );
  const renameMultilocation = useRenameMultilocation(companyDecoded, auth);

  useEffect(() => {
    loadLocationsOfCompany(companyDecoded);

    return () => {
      resetCompanyLocations();
    };
  }, []);

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key]);
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          // Handle forward event
        } else {
          setLocationKeys(keys => [location.key, ...keys]);

          // Handle back event
          loadLocationsOfCompany(companyDecoded);
        }
      }
    });
  }, [locationKeys]);

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <ContextMenu />
        <Row>
          <Col>
            <h1 className="mt-3 mb-3">Property</h1>
          </Col>
        </Row>
        <Row>
          {companyData.isDownloaded &&
            (companyData.multilocations.length > 0 ||
              companyData.locations.length > 0) && (
              <PlusItemCard onClick={createMultilocation} />
            )}
          {companyData.multilocations.length > 0
            ? companyData.multilocations.map(multilocation => (
                <Folder
                  key={multilocation.id}
                  name={encodeURIComponent(multilocation.name)}
                  highLightColor="#00FF00"
                  show={true}
                  searchName={searchName}
                  showContextMenu={showContextMenu}
                  contextMenuProps={{
                    deleteHandler: () => deleteMultilocation(multilocation.id),
                    renameHandler: () => renameMultilocation(multilocation),
                    canShowMove: true,
                    moveContext: {
                      id: multilocation.id,
                      moveObjectType: MOVE_OBJECT_TYPE.MULTILOCATION,
                    },
                  }}
                />
              ))
            : companyData.isDownloaded &&
              companyData.locations.length === 0 && (
                <EmptyPlus onClick={createMultilocation} />
              )}
          {companyData.locations &&
            companyData.locations.map(location => (
              <LocationItem
                data={location}
                key={location.id}
                clickHandler={() => {}}
                hash={settings.hash}
                searchName={searchName}
                showContextMenu={showContextMenu}
              />
            ))}
        </Row>
      </Route>
      <Route path={`${path}/:multilocation`}>
        <DndProvider backend={HTML5Backend}>
          <GridProvider>
            <Multilocation />
          </GridProvider>
        </DndProvider>
      </Route>
    </Switch>
  );
}

const mapStateToProps = state => ({
  companyData: state.company,
  settings: state.settings,
  searchName: state.searchName,
  companies: state.companyList,
  auth: state.auth,
});

const mapDispatchToProps = {
  loadLocationsOfCompany,
  resetCompanyLocations,
  showContextMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
