import React from 'react';
import { Row, Form, Col } from 'react-bootstrap';

export default function Select({
  input,
  label,
  meta: { touched, error },
  type,
  options,
  disabled = false,
  isPopup = false,
}) {
  return isPopup ? (
    <Form.Group>
      {label && <Form.Label className="text-left">{label}</Form.Label>}
      <Form.Control
        {...input}
        style={{ width: '320px' }}
        type={type}
        aria-label={label}
        as="select"
        disabled={disabled}
      >
        {options.map(({ name, value }, index) => (
          <option value={value} key={`${name}${index}`}>
            {name}
          </option>
        ))}
      </Form.Control>
      {touched && error && <span className="text-danger">{error}</span>}
    </Form.Group>
  ) : (
    <Form.Group as={Row}>
      {label && <Col sm={3}>{label}</Col>}
      <Col sm={9}>
        <Form.Control {...input} type={type} aria-label={label} as={'select'}>
          {options.map(({ name, value }, index) => (
            <option value={value} key={`${name}${index}`}>
              {name}
            </option>
          ))}
        </Form.Control>
        {touched && error && <span className="text-danger">{error}</span>}
      </Col>
    </Form.Group>
  );
}
